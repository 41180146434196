import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Libra.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Libra Man
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/libra"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Libra</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Libra Traits</h4>
              
              
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Libra Traits</h1>
                <h2 className="text-sm md:text-base ml-4">Sep 23 - Oct 23</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Libra Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-love");
                  }}>
                  <h5 className="font-semibold text-black">Libra Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-nature");
                  }}>
                  <h5 className="font-semibold text-black">Libra Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-man");
                  }}>
                  <h5 className="font-semibold text-black">Libra Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Libra Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-traits");
                  }}>
                  <h5 className="font-semibold text-black">Libra Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/libra/libra-facts");
                  }}>
                  <h5 className="font-semibold text-black">Libra Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">Libra Traits</p><br/>
<p className="playfair text-black text-xl md:text-3xl">Let's look at some of the positive and negative traits of Libra</p><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Positive Traits of Libra:</p>Romantic, charming, great listener, fair, idealist, and understanding
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Negative Traits of Libra:</p>Lazy, indecisive, unreliable, superficial, and manipulative <br/>
 <br></br>
 <p className="playfair text-black text-xl md:text-3xl">Libra Positive Traits</p><br/>
Romantic
Libra is level-headed, thoughtful, and intelligent. They have a lot of practical knowledge and experience to see the possibilities and take risks. They think ten steps ahead of everyone else's thinking, so they are challenging to fool.
 <br/><br/>
Charming
Libra people are very caring by their nature, but they don't know how to show them to others. You have to understand their way of expression. The nature of caring too much is the one thing a libra battle with all the time.
 <br/><br/>
Great Listener
Libra belongs to deep thinking. They are very good at analyzing another person, and they are very keen observers without even showing. In the end, they clear everything about that person whom they are targeting.
Honest
Libra is the most brutally honest of the zodiac signs. People are fond of this sign because they don't care whether you like them. They still caring for you.
 <br/><br/>
Wild
Libra is a sign of confidence, optimism, and positivity. They are often very adventurous and enjoy exploring the wild, and it's a sign of Libra's health and vigor.
 <br/><br/>
Libra Negative Traits
Impatient
Libra never wants to slow things down. They feel restless and impatient. When things are not going accordingly, they quickly give up on themselves and leave with their frustration and irritable behavior. 
 <br/><br/>
Careless
Sometimes libra act in a very childish and careless manner; unnecessary risks put themselves at risk; you need to stop taking things for granted. One more thing about your behavior which may cause many problems is your careless behavior towards essential things. Because of this,, you have to face criticism in your life.  
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Lazy:</p>Libra people are lazy and will not do work unless the reward is something they desire and can get comfortably without effort.
<br/><br/>
Manipulative: people who belong to the Libra zodiac sign are highly manipulative; they have excellent convincing power to force you to accept what they are saying apart from your own choice.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Attention Seeking:</p>
Libra is known n for being irritating attention-seekers. Some people are ready to harm themselves to take attention from others; the surrounding of these people are very scary. 
 <br/><br/>
Libra Love And Relationships
Libra people are in relationships with other people and things. They are in love and relationships with others, as well as with other things. They also have connections with the planets, stars, and others. They have many relationships with themselves, and there is a great deal of self-consciousness involved in their relationships. 
 <br/><br/>
Libra people are in love with someone or are interested in someone. They focus on the person rather than the relationship. They have a theory of dating and relationships. They're not good at being intimate with someone who doesn't share their views, and they're not good at being intimate with someone who does. They are devoted to someone they love and convert their love into marriage.
 <br/><br/>
Libra Friends And Family
Libra is known for its friendship and loyalty. They are known as "friendly people." They are also known to be very outgoing and have a good sense of humor. They are good people and always looking to have a good time.
 <br/><br/>
When performing a romantic relationship, Libra love their friends and family. Libra people are susceptible to how their loved ones act toward them. They will also take great care of their friends and family, and they will be very protective of them.
Libra has always taken great pride in its social and family connections.
 <br/><br/>
Libra is the most compassionate of the three main signs. They value their friends and family the most. They are generous, kind, caring, and easy to talk to. This is because they are motivated by the desire to help others.
Libra is known to be a logical and logical person.
 <br/><br/>
They are very open to new friendships and new experiences. They will stick with a person even when not entirely the same person. Libra will be loyal to their friends and family and will not be jealous of them.
Libra is the sign of the heart. They are susceptible to others. The heart is the center of their being. Libra people are very caring and considerate.
Libra is known to be very close-knit and loyal. They are known for their friendship and loyalty.
 <br/><br/>
 
Every friendship is unique, after all. But according to astrology, some sun signs play better with others. So who are your best and worst friend matches? Click Here to read more.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Libra Career And Money</p><br/>
Libra and Career
Work-life balance is their most significant career strength. Libra is a fierce and determined group committed to working hard, no matter what it takes.
Libra make it their business to work very hard and push themselves to the max. They like having flexibility, so they get their job done well. They are very dedicated to their works. 
They like having the freedom to work more when the mood takes them. Libra is proud of its work and wants to continue doing it well. Thus, Libra needs a career that provides them plenty of liberty and excitement. But for Libra, sometimes it is difficult to focus on one thing because they have many interests in different fields and love to explore them. 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Libra and Money</p><br/>
Making money is very important for Libra. No matter what they are doing, they always prefer to make more money. They choose work according to their choice and don't want any other interference in their work. The Libra people are very disciplined and rigid when it comes to money. They try to save money for rainy days in a very disciplined manner. They do not even think about making a small mistake. Libra believes there is no way to come out. When you spend all your money on unnecessary things, you spend wisely to understand the value of money. 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">How To Attract Libra </p><br/>
Libra are some of the most exciting, adventurous, and charming people. Let's start if you want to attract them with your charm and uniqueness. To draw a free-spirited archer, you need to put your game face on. 
Follow these simple steps to win the hearts of Sagittarius.
 <br/><br/>
 
 <p className="font-bold text-orange-500 inline-block mr-2">Be fun and playful:  </p> Avoid being unfavorable around Libra. They are very fun-loving people, so be entertaining and lively. Go out with them and make your day memorable and enjoyable for Libra.
 <br/><br/>
 
 <p className="font-bold text-orange-500 inline-block mr-2">Do not act clingy:</p> Libra loves freedom, so they don't like highly sticky people. They want people in their surroundings who won't clutter their liberty.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Be ready to travel:</p> To attract Libra, you should be a traveler. When talking to them, ask them about their journeys, and explore your travel experience, making your conversation lighter. They start taking an interest in you if you suggest they explore some new places together. 
 <br/><br/>
If you know more about Libra's personality traits, find out how you match with other Zodiac Signs.
 <br/><br/>
Compatible Signs Libra Should Consider
Libra sign is gregarious, fun-loving, and places great value on liberation and freedom. Libra's three most compatible signs are Aries, Aquarius, and Libra.
 <br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
